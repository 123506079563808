@import "../../styles/variables.scss";
@import "../../styles/global.scss";

#timeline-content {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .timeline-heading {
    text-align: center;
    h2 {
      text-align: center;
      flex-basis: 50%;
      font-family: "Montserrat", sans-serif;
      font-size: 3.5rem;
      line-height: 3rem;
      margin: 0px;
      text-transform: uppercase;
      margin-bottom: 2rem;
      @media (max-width: 768px) {
        font-size: 2em;
      }
    }
  }
}

.timeline {
  border-left: 4px solid var(--highlight-color);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.03);
  color: white;
  font-family: var(--font-text);
  margin: 50px auto;
  letter-spacing: 0.5px;
  position: relative;
  line-height: 1.4em;
  font-size: 1.03em;
  padding: 50px;
  list-style: none;
  text-align: left;
  font-weight: 100;
  max-width: 60%;

  .event {
    transition: all 0.3s ease-in-out;
    h3 {
      font-weight: bold;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    border-bottom: 1px dashed var(--highligt-color-light);
    padding-bottom: calc(50px * 0.5);
    margin-bottom: 50px;
    position: relative;

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    &:hover {
      border-bottom: 1px dashed white;
    }

    &:before,
    &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    // &:before {
    //   left: calc((180px * 0.6 + 50px + 4px + 11px + 4px * 2) * 1.5 * -1);
    //   color: rgba(255, 255, 255, 0.4);
    //   content: attr(data-date);
    //   text-align: right;
    //   font-weight: 100;
    //   font-size: 0.9em;
    //   min-width: 120px;
    //   font-family: var(--font-title);
    // }

    &:after {
      box-shadow: 0 0 0 4px var(--highlight-color);
      left: calc((50px + 5px + 22px * 0.35) * -1);
      background: var(--highlight-color);
      border-radius: 50%;
      height: 22px;
      width: 22px;
      content: "";
      top: 5px;
    }
  }
}

/* Exemple de Media Query pour les écrans plus petits */
@media (max-width: 768px) {
  .timeline {
    width: 100%;
    max-width: 85%;
  }

  .event {
    text-align: left;
    p {
      text-align: left;
    }
    h3 {
      text-align: left !important;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .event:before {
    left: auto;
    right: 0;
    text-align: center;
  }

  .event h3 {
    font-size: 1.2em;
  }

  .event p {
    font-size: 1em;
  }
}
