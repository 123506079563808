@import "./variables.scss";
body,
#root {
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: var(--background-color);
}

::-webkit-scrollbar {
  display: none;
}


::-webkit-scrollbar-thumb {
  background-color: var(--highlight-color);
  height: 5px;
  border-radius: 5px;
}


::-webkit-scrollbar-track {
  background-color: var(--background-color);
}


::-webkit-scrollbar-thumb:hover {
  background-color: var(--highlight-color);
}

::-webkit-scrollbar-thumb:active {
  background-color: var(--highlight-color);
}

.page-transition-enter {
  opacity: 0;
  transform: scale(0.95);
  filter: blur(50px);
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    filter 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.page-transition-enter-active {
  opacity: 1;
  transform: scale(1);
  filter: blur(0);
}

.page-transition-exit {
  opacity: 1;
  transform: scale(1);
  filter: blur(0);
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    filter 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.page-transition-exit-active {
  opacity: 0;
  transform: scale(0.95);
  filter: blur(10px);
}

.Toastify__toast-container {
  z-index: 99999999999 !important;
}

.ConfettiComponent {
  z-index: 999999999999 !important;
  position: absolute;
  top: 0;
  left: 0;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  // overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
a,
p,
span {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: white;
  margin: 0px;
}

.active-link {
  color: var(--highlight-color) !important;
}

a {
  transition: color 200ms ease-in-out;
  text-decoration: none;
  span {
    transition: color 200ms ease-in-out;
  }
}

a:hover {
  color: var(--highlight-color) !important;
  span {
    color: var(--highlight-color) !important;
  }
}
