@import "../../styles/variables.scss";
@import "../../styles/global.scss";

main > .contact-form {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;

  @media (max-width: 768px) {
    margin-top:  1rem;
    flex-direction: column-reverse;
    padding: 2rem;
    height: 100%;
    overflow-y: scroll;
    max-height: 100%;
  }
}

.contact-form {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;

  .valid {
    border: 4px solid green !important;
  }

  .invalid {
    border: 4px solid red !important;
  }

  .error {
    color: red;
    font-size: 0.875em;
    margin-top: 0.25em;
  }

  h2 {
    text-align: center;
    flex-basis: 50%;
    font-family: "Montserrat", sans-serif;
    font-size: 3.5rem;
    line-height: 3rem;
    margin: 0px;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 70vw !important;

    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.25em;

      input,
      textarea {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-size: 14px;
        color: black;

        &:focus {
          border-color: #666;
          outline: none;
        }
      }

      textarea {
        resize: vertical;
        min-height: 100px;
      }
    }

    button {
      align-self: center;
      padding: 10px 20px;
      border: none;
      background-color: white;
      color: var(--main-color);
      font-size: 16px;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: var(--main-color);
        color: white;
        border: 1px solid white;
      }

      &:active {
        background-color: #1e7e34;
      }
    }

    .submit-container {
      display: flex;
      justify-content: center;
    }
  }
}
@media (max-width: 768px) {
  .contact-form h2 {
    font-size: 2rem; /* Réduisez la taille de la police pour les appareils mobiles */
  }
}
