@import "../../styles/variables.scss";
@import "../../styles/global.scss";

a {
  transition: color 200ms ease-in-out;
  text-decoration: none;
  span {
    transition: color 200ms ease-in-out;
  }
}

nav {
  display: flex;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid var(--border-color);
  z-index: 10000000;
  background-color: var(--background-color);
}

nav .nav-section {
  padding: 3rem 2rem;
  display: flex;
  gap: 1rem;
  border-left: 1px solid var(--border-color);
  align-items: center;
  justify-content: center;
}

#nav-mobile-section {
  display: flex;
  flex-basis: calc(100% * (2 / 3));
  z-index: 2;
}

#nav-toggle-button {
  align-items: center;
  background-color: transparent;
  border: none;
  border-left: 1px solid var(--border-color);
  font-weight: bolder;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  display: none;
  gap: 0.8rem;
  height: 100%;
  justify-content: center;
  outline: none;
  padding: 0rem 3rem;
  position: relative;
  z-index: 3;
  span{
    font-family: "Rubik", sans-serif;
    font-weight: bold;
  }
}

#nav-toggle-button:hover,
#nav-toggle-button:hover > span {
  color: var(--highlight-color);
}

#nav-toggle-button > span,
#nav-toggle-button > i {
  display: inline-block;
  height: 1rem;
  line-height: 1rem;
}

#nav-social-section,
#nav-contact-section {
  flex-grow: 1;
  a {
    color: white;
    font-weight: bolder;
  }
}

#nav-logo-section {
  flex-basis: calc(100% / 3);
  justify-content: flex-start;
  a {
    color: white;
    text-decoration: none;

    span {
      margin-right: 1rem;
      font-family: "Rubik", sans-serif;
      font-weight: bolder;
      color: white;
    }
  }
}

#nav-logo-section > a > i {
  font-size: 2.5rem;
}

#nav-link-section {
  flex-basis: 50%;
  gap: 6rem;
  a {
    font-weight: bolder;
  }
}

#nav-social-section {
  gap: 3rem;
}


@media (max-width: 1200px) {
  #nav-mobile-section {
    flex-basis: calc(100% * 0.75);
  }

  #nav-logo-section {
    flex-basis: calc(100% * 0.25);
  }

  #nav-link-section {
    flex-basis: calc(100% / 3);
    gap: 3rem;
  }
}
@media (max-width: 800px) {
  nav {
    justify-content: space-between;
  }

  nav[data-toggled="true"] > #nav-mobile-section {
    transform: translateY(0%);
  }

  nav[data-toggled="true"] > #nav-toggle-button {
    border-left: none;
  }

  nav[data-transitionable="true"] > #nav-mobile-section {
    transition: transform 400ms ease;
  }

  nav .nav-section {
    border-left: none;
    padding: 1.5rem 1rem;
  }

  #nav-logo-section > a > i {
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  #nav-mobile-section {
    background-color: var(--background-color);
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    position: fixed;
    transform: translateY(-100%);
  }

  #nav-toggle-button {
    display: flex;
  }

  #nav-link-section {
    flex-basis: 75%;
    flex-direction: column;
    gap: 4rem;
    text-align: center;
  }

  #nav-link-section > a {
    font-size: 2rem;
  }

  #nav-social-section > a {
    font-size: 1.5rem;
  }

  #nav-contact-section {
    padding-bottom: 3rem;
  }
}
