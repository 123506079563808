@import "../../styles/variables.scss";
@import "../../styles/global.scss";

article {
  height: 80%;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 2fr 1fr;
  position: absolute;
  left: 0px;
  bottom: 0px;
  transition: transform 400ms ease;
}

article[data-status="inactive"] {
  transform: translateX(-100%);
  transition: none;
}

article[data-status="active"] {
  transform: translateX(0%);
}

article[data-status="before"] {
  transform: translateX(-100%);
}

article[data-status="after"] {
  transform: translateX(100%);
}

article[data-status="becoming-active-from-before"] {
  transform: translateX(-100%);
  transition: none;
}

article[data-status="becoming-active-from-after"] {
  transform: translateX(100%);
  transition: none;
}

article > .article-section {
  height: 100%;
  display: flex;
}

article > .article-description-section,
article > .article-nav-section {
  border-left: 1px solid var(--border-color);
}

article > .article-title-section,
article > .article-nav-section {
  border-top: 1px solid var(--border-color);
}

article:first-child > .article-image-section {
  background-image: url("../../assets/agriculture-local-territoire-filiere-agricole.webp");

}

article:nth-child(2) > .article-image-section {
  background-image: url("../../assets/UCM3.webp");
}

article:nth-child(3) > .article-image-section {
  background-image: url("../../assets/libeduc.webp");
}

article:nth-child(4) > .article-image-section {
  background-image: url("../../assets/Capture_d_écran_2023-01-17_181639_ysttim.webp");
}

article:nth-child(5) > .article-image-section {
  background-image: url("../../assets/feedmyplant.webp");
  background-size: contain;
  background-repeat: no-repeat;
}

article:nth-child(6) > .article-image-section {
  background-image: url("../../assets/Capture_d_écran_2023-09-07_224504_n3bdlg.webp");
}

article > .article-image-section {
  background-position: center;
    background-size: cover;
  background-repeat: no-repeat;
}
.no-lazy-load {
  background-image: none;
}

article > .article-description-section {
  flex-direction: column;
  justify-content: flex-end;
  padding: 4rem;
}

article > .article-description-section > p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.25em;
}

article > .article-description-section > p > a:hover {
  text-decoration: underline;
}

article > .article-description-section > .article-badges {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  .badge {
    transition: all 400ms ease-in-out;
    padding: 5px 10px;
    color: var(--background-color);
    font-weight: bold;
    background-color: white;
    border-radius: 5px;
    font-size: 0.9em;
  }
  .badge:hover {
    background-color: var(--highlight-color);
    color: white;
  }
}

article > .article-title-section {
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;
}

article > .article-title-section * {
  color: white;
}

article > .article-title-section > h2 {
  flex-basis: 50%;
  font-family: "Montserrat", sans-serif;
  font-size: 3.5rem;
  line-height: 3rem;
  margin: 0px;
  text-transform: uppercase;
}

// article > .article-title-section > i {
//   color: rgba(255, 255, 255, 0.75);
// }
article .article-title-section svg.fa-plus path {
  fill: rgba(255, 255, 255, 0.75);
  font-size: 4rem;
}

article > .article-nav-section > .article-nav-button {
  background-color: transparent;
  flex-grow: 1;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.75);
  font-size: 3rem;
  cursor: pointer;
}

article > .article-nav-section > .article-nav-button:hover {
  background-color: rgba(255, 255, 255, 0.02);
}

article > .article-nav-section > .article-nav-button:nth-child(2) {
  border-left: 1px solid var(--border-color);
}

@media (max-width: 1200px) {

  article {
    grid-template-columns: none;
    grid-template-rows: 3fr repeat(3, 0.75fr);
    height: 80%;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }

  article > .article-title-section > h2 {
    font-size: 3rem;
    line-height: 2.6rem;
  }

  article > .article-title-section > i {
    font-size: 3rem;
  }

  article > .article-description-section,
  article > .article-nav-section {
    border-left: none;
  }

  article > .article-image-section {
    order: 1;
  }

  article > .article-title-section {
    border-bottom: 1px solid var(--border-color);
    order: 2;
  }

  article > .article-description-section {
    justify-content: center;
    order: 3;
  }

  article > .article-nav-section {
    border-bottom: 1px solid var(--border-color);
    order: 4;
  }
}

@media (max-width: 800px) {
  article {
    overflow-y: scroll;
    grid-template-rows: 3fr repeat(3, 0.75fr);
    height: 80%;
    width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
  }

  article > .article-title-section {
    padding: 2rem;
  }

  article > .article-title-section > h2 {
    flex-basis: 70%;
    font-size: 1.75em;
    line-height: 1.5rem;
  }

  article > .article-title-section > i {
    font-size: 2rem;
  }

  article > .article-description-section {
    padding: 2rem;
  }

  article > .article-description-section > p {
    font-size: 1rem;
  }
}

/* -- YT link / Source link styles -- */

article > .article-description-section > p > .source-link {
  color: var(--highlight-color);
  display: inline;
}

article .yt-link,
article .yt-link > i {
  color: var(--yt-color);
  display: inline;
}
