@import "../../styles/variables.scss";
@import "../../styles/global.scss";

main > .about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  height: 80%;


  @media (max-width: 1200px) {
    overflow-y: scroll;
    max-height: 100%;
    flex-direction: column-reverse;
    padding: 2rem;
  }
}

.about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    overflow-y: scroll;
    max-height: 100%;
    flex-direction: column;
  }

  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70vw;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 5rem;
    margin: 1rem;

    @media (max-width: 768px) {
      padding: 0rem;
    }

    h2 {
      text-align: center;
      flex-basis: 50%;
      font-family: "Montserrat", sans-serif;
      font-size: 3.5rem;
      line-height: 3rem;
      margin: 0px;
      text-transform: uppercase;
      margin-bottom: 2rem;
      @media (max-width: 768px) {
        font-size: 2em;
      }
    }


    p {
      text-align: justify !important;
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.25em;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 1.2em;
      }
    }

    .navigation-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 50px;
      gap: 3rem;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .navigation-buttons div {
      width: max-content;
      height: 3rem;
      // overflow: hidden; /* Permet à l'effet clip-path de déborder du conteneur */
    }
  }

  img {
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    margin-right: 50px;
    border: 5px solid var(--highlight-color);

    @media (max-width: 768px) {
      width: 50vw;
      height: 50vw;
      margin-right: 0;
    }
  }

  .button,
  .button:hover,
  .button:visited {
    color: white;
  }
  .button {
    overflow: hidden;
    transition: font-weight 0.1s ease-in-out, -webkit-clip-path 0.1s ease-in-out;
    transition: font-weight 0.1s ease-in-out, clip-path 0.1s ease-in-out;
    transition: font-weight 0.1s ease-in-out, clip-path 0.1s ease-in-out,
      -webkit-clip-path 0.1s ease-in-out;
    display: inline-block;
    padding: 1em 1.5em;
    font-family: "Rubik", sans-serif;
    font-weight: bolder;
    font-weight: 400;
    text-decoration: none;
    text-align: center;
    line-height: normal;
    -webkit-appearance: none;
    background-color: var(--highlight-color);
    border: 0;
    box-shadow: none;
    -webkit-clip-path: polygon(
      5% 10%,
      46% 1%,
      46% 10%,
      100% 0,
      95% 90%,
      31% 100%,
      31% 90%,
      0 100%
    );
    clip-path: polygon(
      5% 10%,
      46% 1%,
      46% 10%,
      100% 0,
      95% 90%,
      31% 100%,
      31% 90%,
      0 100%
    );
  }
  a,
  a:visited {
    color: var(--highlight-color);
    text-decoration-thickness: 0.1em;
    text-decoration-width: 0.1rem;
    transition: all 0.1s ease-in-out;
    transition-property: text-decoration-thickness, text-decoration-width,
      -webkit-text-decoration-color;
    transition-property: text-decoration-color, text-decoration-thickness,
      text-decoration-width;
    transition-property: text-decoration-color, text-decoration-thickness,
      text-decoration-width, -webkit-text-decoration-color;
  }
  .button,
  .button:hover,
  .button:visited {
    color: white;
  }

  a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
  }

  .button:hover {
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    -webkit-clip-path: polygon(
      5% 10%,
      66% 1%,
      66% 10%,
      100% 0,
      95% 90%,
      51% 100%,
      51% 90%,
      0 100%
    );
    clip-path: polygon(
      5% 10%,
      66% 1%,
      66% 10%,
      100% 0,
      95% 90%,
      51% 100%,
      51% 90%,
      0 100%
    );
  }

  .button,
  .button:hover,
  .button:visited {
    overflow: hidden;
    color: white;
  }
  .button:hover {
    transition: all 0.1s ease-in-out;
    overflow: hidden;
    -webkit-text-decoration-color: var(--color-accent);
    text-decoration-color: var(--color-accent);
    text-decoration-thickness: 0.2em;
    text-decoration-width: 0.2em;
  }
}
